import React from 'react'
import Layout from '../components/layout'

const AboutPage = () => (
    <Layout siteTitle="About Us">
        <div className="bg-gray-200 min-h-screen">
            <h1 className="text-7xl uppercase font-bold text-center pt-10">About Us</h1>
            
            <div className="mt-16 space-y-16 p-10">
                <div className="space-y-5">
                    <h2 className="uppercase text-5xl font-bold">Who We Are</h2>
                    <p className="p-10 text-2xl">
                    We at Knockout Photo are a team that's passionate about making sure everyone finds a home they love. 
                    And we know that realtors are a key part of that goal. Which is why we are dedicated to providing excellent photos, videos, 
                    and promotional materials to help you match your clients with homes they'll cherish for years to come.
                    </p>
                </div>
                
                <div className="space-y-5" >
                    <h2 className="uppercase text-5xl font-bold">What We Do</h2>
                    <p className="p-10 text-2xl">
                    We take stunning photos and video that makes your listing come to life for home buyers. 
                    With our high-quality photos taken by our professional photographers, and our professional videographers 
                    taking video that practically transports your clients into the home: 
                    we create first-class promotional materials (flyers, webpages, virtual tours, and galleries) 
                    that are sure to give you an edge when showcasing the properties you have to offer.
                    </p>
                </div>
            </div>
        </div>
    </Layout>
)

export default AboutPage